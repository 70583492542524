<template>
  <CRow>
    <CCol col="12" xl="12">
      <Spinner v-if="$store.state.loadSpinner" />
      <CCard v-else>
        <CCardHeader> المحامون </CCardHeader>
        
        <CCardBody>
          <CRow class="mt-3">
            <CCol sm="12" md="3">
              <CInput invalid description="" placeholder="اسم المحامي" autocomplete="name" v-model.trim="search.name" />
            </CCol>
            <CCol sm="12" md="3">
              <CInput invalid description="" placeholder="موبايل المحامي" type="number" autocomplete="name" v-model.trim="search.phone" />
            </CCol>
            <CCol sm="12" md="3">
              <CButton @click="searchForm" type="button" class="btn-sm" color="primary">
                <CIcon name="cil-search" /> بحث
              </CButton>
              <CButton @click="resetForm" type="button" class=" mx-2" color="danger">
                تفريغ
              </CButton>
            </CCol>
          </CRow>
        </CCardBody>
        
        <CCardBody>
          <div class="table-responsive">
            <table class="table table-striped table-bordered table-hover mb-5">
              <thead>
                <tr class="table-primary">
                  <th scope="col">#</th>
                  <th scope="col">الاسم</th>
                  <th scope="col">الموبايل</th>
                  <th scope="col">الايميل</th>
                  <th scope="col">صورة الهوية</th>
                  <th scope="col">حالة التسجيل</th>
                  <th scope="col">الصورة</th>
                  <th scope="col">الحالة</th>
                  <th scope="col">الاجراءات</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in items" :key="item.id">
                  <th scope="row">{{ item.id }}</th>
                  <td>{{ item.name }}</td>
                  <td>{{ item.phone }}</td>
                  <td>{{ item.email }}</td>
                  <td>
                    <CImg height="80px" width="80px" :src="item.verification_image_url" fluid />
                  </td>
                  <td>
                    <b :class="`text-${getBadge(item.register_status)}`">
                      {{ item.register_status == "1" ? "مكتمل" : "غير مكتمل" }}
                    </b>
                  </td>
                  <td>
                    <CImg
                      height="80px"
                      width="80px"
                      :src="item.personal_image_url"
                      fluid
                    />
                  </td>
                  
                  <td>
                    <b :class="`text-${getBadge(item.status)}`">
                      {{ item.status == "1" ? "نشط" : "متوقف" }}
                    </b>
                  </td>
                  <td>
                    <CButton
                      color="info"
                      @click="
                        $router.push({
                          path: `lawyers/${item.id}`,
                          params: { lawyer: item },
                        })
                      "
                    >
                      <CIcon name="cil-low-vision"></CIcon>
                    </CButton>
                    <!-- <div> -->
                      <CButton v-if="item.status == '0'" class="mx-2" color="success" @click="updateStatus(1, item.id)">
                        تفعيل
                      </CButton>
                      <CButton v-else color="danger" class="mx-2" @click="updateStatus(0, item.id)">
                        ايقاف
                      </CButton>
                    <!-- </div> -->
                    
                    <!-- <CButton
                      class="mx-1"
                      color="danger"
                      @click="stopLawyer(item.id)"
                    >
                      <CIcon name="cil-power-standby"></CIcon>
                    </CButton> -->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <CPagination :active-page.sync="currentPage" :pages="totalPages" />
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import Vue from 'vue';

export default {
  name: "Lawyers",
  data() {
    return {
      items: [],
      activePage: 1,
      currentPage: 1,
      allPages: 10,
      search: {
        name: null, 
        phone: null
      }
    };
  },
  watch: {
    currentPage: {
      handler: function (val, oldVal) {
        this.fetchData(val);
      },
      deep: true,
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData(page = 1, data = null) {
      this.toggleLoadSpinner();
      let url = `lawyers?page=${page}`;
      if (data) {
        url += data;
      }
      this.$http
        .get(url)
        .then((res) => {
          // handle success
          this.items = res.data.data.data;
          this.totalPages = res.data.data.last_page;
          this.toggleLoadSpinner();
        })
        .catch((error) => {
          //
          this.toggleLoadSpinner();
        });
    },

    searchForm() {

      var query = "";
      if (this.search.name) {
        query += `&name=${this.search.name}`;
      }
      if (this.search.phone) {
        query += `&phone=${this.search.phone}`;
      }

      this.fetchData(1, query);
    },
    
    resetForm() {
      this.search.name = null;
      this.search.phone = null;
    },

    toggleLoadSpinner() {
      this.$store.commit("toggleLoadSpinner");
    },

    stopLawyer(itemId) {
      console.log(itemId);
    },

    getBadge(status) {
      switch (status) {
        case "active":
          return "success";
        case "inactive":
          return "secondary";
        case "Pending":
          return "warning";
        case "Banned":
          return "danger";
        default:
          "primary";
      }
    },
    pageChange(val) {
      this.$router.push({ query: { page: val } });
    },

    updateStatus(status, id) {
      this.toggleLoadSpinner();

      this.$http
        .put(`/lawyers/status/${id}`, {status})
        .then((res) => {
          console.log(res.data);
          const index = this.items.findIndex(object => {
            return object.id === id;
          });
          Vue.set(this.items, index, res.data.data);
          this.toggleLoadSpinner();
        })
        .catch((error) => {
          // handle error
          this.toggleLoadSpinner();
        });
    }
    
  },
};
</script>